import { useState } from "react";

import { appStore } from "~/stores/app";
import { positionStore } from "~/stores/position";
import { getTableItems } from "~/utils/tile";
import { getId, trans } from "~/utils/utils";
import { changeTile } from "~/stores/map";
import { MIN_GRID_ZOOM } from "~/const";

// styly
import "./style.less";

interface IState {
	tiles: Array<Array<string>>;
	incTiles: Array<string>;
	maxX: number;
	maxY: number;
}

export default function Table() {
	const { app } = appStore(appState => ({
		app: appState.app,
	}));
	const { position } = positionStore(posState => ({
		position: posState.position,
	}));
	const [state, setState] = useState<IState>(() => {
		const tiles = getTableItems(app.selectedTiles, position.z);

		return {
			tiles,
			incTiles: [],
			maxX: tiles[0].length,
			maxY: tiles.length,
		};
	});

	function isItemChecked(item: string) {
		return app.selectedTiles.indexOf(item) !== -1;
	}

	function onChange(item: string) {
		changeTile(item);
	}

	function addItem(axisX: number, axisY: number) {
		setState(prev => {
			const addTiles = [];

			if (axisX === 1) {
				addTiles.push(prev.tiles[1][prev.maxX - 1]);
			} else if (axisX === -1) {
				addTiles.push(prev.tiles[1][0]);
			} else if (axisY === 1) {
				addTiles.push(prev.tiles[prev.maxY - 1][1]);
			} else if (axisY === -1) {
				addTiles.push(prev.tiles[0][1]);
			}

			const incTiles = [
				...prev.incTiles,
				...prev.incTiles.length === 0
					? [
						prev.tiles[1][1],
						/* eslint-disable no-magic-numbers */
						prev.tiles[prev.maxX - 2][prev.maxY - 2],
					]
					: [],
				...addTiles,
			];
			const tiles = getTableItems([
				...app.selectedTiles,
				...incTiles,
			], position.z);

			return {
				...prev,
				tiles,
				incTiles,
				maxX: tiles[0].length,
				maxY: tiles.length,
			};
		});
	}

	return <div className="table">
		<div className="centered">
			{ position.z >= MIN_GRID_ZOOM && <table>
				<tbody>
					<tr>
						<td></td>
						<td colSpan={state.maxX} className="arrow up">
							<button type="button" className="arrow-outer" onClick={() => addItem(0, -1)}>
								<span className="arrow-icon">Z</span>
								<span className="caption">{ trans("table_add_row_up") }</span>
							</button>
						</td>
						<td></td>
					</tr>
					{ state.tiles.map((lineItem, lineInd) => <tr key={getId()}>
						{ lineInd === 0 && <td rowSpan={state.maxY} className="arrow left">
							<button type="button" className="arrow-outer" onClick={() => addItem(-1, 0)}>
								<span className="arrow-icon">b</span>
								<span className="caption">{ trans("table_add_cell_left") }</span>
							</button>
						</td> }
						{ lineItem.map(item => <td key={item} className="filled">
							<label className="custom">
								<input type="checkbox" checked={isItemChecked(item)} onChange={() => onChange(item)} />
								<span>&nbsp;</span>
								<span className="name">{ item }</span>
							</label>
						</td>) }
						{ lineInd === 0 && <td rowSpan={state.maxY} className="arrow right">
							<button type="button" className="arrow-outer" onClick={() => addItem(1, 0)}>
								<span className="caption">{ trans("table_add_cell_right") }</span>
								<span className="arrow-icon">P</span>
							</button>
						</td> }
					</tr>) }
					<tr>
						<td></td>
						<td colSpan={state.maxX} className="arrow down">
							<button type="button" className="arrow-outer" onClick={() => addItem(0, 1)}>
								<span className="caption">{ trans("table_add_row_down") }</span>
								<span className="arrow-icon">R</span>
							</button>
						</td>
						<td></td>
					</tr>
				</tbody>
			</table> }
		</div>
	</div>;
}
