import { useState } from "react";

import { appStore } from "~/stores/app";
import { USER_FORM_STATES, DEFAULT_STATE_VALUE } from "~/const";
import { trans } from "~/utils/utils";
import { getAPIFetch } from "~/providers/api";
import { getGDPRLink } from "~/utils/lang-data";

// styly
import "./style.less";

interface IState {
	name: string;
	nameError: boolean;
	surName: string;
	surNameError: boolean;
	phone: string;
	phoneError: boolean;
	email: string;
	emailError: boolean;
	note: string;
	noteError: boolean;
	street: string;
	streetError: boolean;
	city: string;
	cityError: boolean;
	zipCode: string;
	zipCodeError: boolean;
	country: string;
	countryError: boolean;
	sendInfo: string;
	sendInfoError: boolean;
	wasSend: boolean;
}

interface IUserForm {
	onBack: () => void;
	onThankYou: (withHelp: boolean) => void;
}

export default function UserForm({
	onBack = () => {},
	onThankYou = () => {},
}: IUserForm) {
	const { app } = appStore(appState => ({
		app: appState.app,
	}));
	const [state, setState] = useState<IState>({
		name: "",
		nameError: false,
		surName: "",
		surNameError: false,
		phone: "",
		phoneError: false,
		email: "",
		emailError: false,
		note: "",
		noteError: false,
		street: "",
		streetError: false,
		city: "",
		cityError: false,
		zipCode: "",
		zipCodeError: false,
		country: DEFAULT_STATE_VALUE[app.lang] || "",
		countryError: false,
		sendInfo: "",
		sendInfoError: false,
		wasSend: false,
	});
	const withHelp = app.userFormState === USER_FORM_STATES.HELP;

	function updateState(changeData: object) {
		setState(prev => ({
			...prev,
			...changeData,
		}));
	}

	function getInfo(): object {
		const info = {
			...app.userFormData,
			lang: app.lang,
		};

		if (info.pos === "") {
			delete info.pos;
		}

		return info;
	}

	async function send() {
		try {
			setState(prev => ({
				...prev,
				sendInfo: trans("print_send_start"),
				showError: false,
				wasSend: true,
			}));

			// download, print a advise
			const sendData = {
				mode: withHelp ? "advise" : "print",
				info: getInfo(),
				contact: {
					name: state.name.trim(),
					surname: state.surName.trim(),
					phone: state.phone.trim(),
					email: state.email.trim(),
					street: state.street.trim(),
					city: state.city.trim(),
					zipcode: state.zipCode.trim(),
					country: state.country.trim(),
					note: state.note.trim(),
					consent: true,
				},
			};
			const request = await getAPIFetch(sendData);

			/* eslint-disable no-magic-numbers */
			if (request.status >= 200 && request.status < 300) {
				setState(prev => ({
					...prev,
					sendInfo: "",
					showError: false,
					showThankYou: true,
					wasSend: false,
				}));
				onThankYou(withHelp);
			} else {
				setState(prev => ({
					...prev,
					sendInfo: trans("print_send_error"),
					sendInfoError: true,
					wasSend: false,
				}));
			}
		} catch (exc) {
			/* eslint-disable no-console */
			console.log(exc);
			setState(prev => ({
				...prev,
				sendInfo: trans("print_send_error"),
				sendInfoError: true,
				wasSend: false,
			}));
		}
	}

	function beforeSend() {
		let errors = 0;
		const changes: Partial<IState> = {
			nameError: false,
			surNameError: false,
			phoneError: false,
			emailError: false,
			noteError: false,
			streetError: false,
			cityError: false,
			zipCodeError: false,
		};

		if (!state.name) {
			errors++;
			changes.nameError = true;
		}

		if (!state.surName) {
			errors++;
			changes.surNameError = true;
		}

		if (!state.phone.match(/(\+\d{3})?\s*\d{3}\s*\d{3}\s*\d{3}/u)) {
			errors++;
			changes.phoneError = true;
		}

		if (!state.email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/u)) {
			errors++;
			changes.emailError = true;
		}

		if (!state.note && withHelp) {
			errors++;
			changes.noteError = true;
		}

		if (!withHelp) {
			if (!state.street.match(/\w+/u) || !state.street.match(/\d+/u)) {
				errors++;
				changes.streetError = true;
			}

			if (!state.city) {
				errors++;
				changes.cityError = true;
			}

			if (!state.zipCode.match(/\d{3}\s*\d{2}/u)) {
				errors++;
				changes.zipCodeError = true;
			}

			if (!state.country) {
				errors++;
				changes.countryError = true;
			}
		}

		setState(prev => ({
			...prev,
			...changes,
		}));

		if (errors === 0) {
			send();
		}
	}

	return <div className="user-form">
		<h2>
			{ withHelp ? trans("print_order_help") : trans("print_order_standard") }
		</h2>
		<p className="desc">
			{ withHelp ? trans("print_order_help_desc") : trans("print_order_desc") }
		</p>
		<div role="group" aria-label={ trans("print_contact_data") }>
			<h3>{ trans("print_contact_data") }</h3>
			<div className="form-line">
				<label className="caption" htmlFor="firstname">{ trans("print_contact_data_name") }</label>
				<span className="input-holder">
					<input type="text" placeholder={ trans("print_contact_data_name_placeholder") } value={state.name} id="firstname" aria-required="true" aria-invalid={state.nameError} onChange={event => updateState({ name: event.target.value })} />
					{ state.nameError && <span className="error" role="tooltip" id="id-firstname">{ trans("print_name_error") }</span> }
				</span>
			</div>
			<div className="form-line">
				<label className="caption" htmlFor="surname">{ trans("print_contact_data_surname") }</label>
				<span className="input-holder">
					<input type="text" placeholder={ trans("print_contact_data_surname_placeholder") } value={state.surName} id="surname" aria-required="true" aria-invalid={state.surNameError} onChange={event => updateState({ surName: event.target.value })} />
					{ state.surNameError && <span className="error" role="tooltip" id="id-surname">{ trans("print_surname_error") }</span> }
				</span>
			</div>
			<div className="form-line">
				<label className="caption" htmlFor="phone">{ trans("print_contact_data_phone") }</label>
				<span className="input-holder">
					<input type="tel" placeholder={ trans("print_contact_data_phone_placeholder") } value={state.phone} id="phone" aria-required="true" aria-invalid={state.phoneError} onChange={event => updateState({ phone: event.target.value })} />
					{ state.phoneError && <span className="error" role="tooltip" id="id-phone">{ trans("print_phone_error") }</span> }
				</span>
			</div>
			<div className="form-line">
				<label className="caption" htmlFor="email">{ trans("print_contact_data_email") }</label>
				<span className="input-holder">
					<input type="email" placeholder={ trans("print_contact_data_email_placeholder") } value={state.email} id="email" aria-required="true" aria-invalid={state.emailError} onChange={event => updateState({ email: event.target.value })} />
					{ state.emailError && <span className="error" role="tooltip" id="id-email">{ trans("print_email_error") }</span> }
				</span>
			</div>
		</div>
		<div role="group" aria-label={ trans("print_contact_additional") }>
			<h3>{ trans("print_contact_additional") }</h3>
			<p>
				{ withHelp ? trans("print_contact_additional_desc") : "" }
			</p>
			<div className="form-line">
				<span className="caption">
					<label htmlFor="note" className="title">{ trans("print_contact_additional_note") }</label>
					<span className="optional">{ withHelp ? trans("print_contact_additional_note_required") : trans("print_contact_additional_note_optional") }</span>
				</span>
				<span className="input-holder">
					<textarea id="note" aria-required={withHelp} aria-invalid={state.noteError} onChange={event => updateState({ note: event.target.value })} value={ state.note }></textarea>
					{ state.noteError && <span className="error" role="tooltip" id="id-note">{ trans("print_note_error") }</span> }
				</span>
			</div>
		</div>
		<div role="group" aria-label={ trans("print_contact_address") }>
			<h3>
				{ trans("print_contact_address") }
				<span>{ withHelp ? trans("print_contact_additional_note_optional") : "" }</span>
			</h3>
			<p></p>
			<div className="form-line">
				<label className="caption" htmlFor="street">{ trans("print_contact_address_street") }</label>
				<span className="input-holder">
					<input type="text" placeholder={ trans("print_contact_address_street_placeholder") } value={state.street} id="street" aria-required={!withHelp} aria-invalid={state.streetError} onChange={event => updateState({ street: event.target.value })} />
					{ state.streetError && <span className="error" role="tooltip" id="id-street">{ trans("print_street_error") }</span> }
				</span>
			</div>
			<div className="form-line">
				<label className="caption" htmlFor="city">{ trans("print_contact_address_city") }</label>
				<span className="input-holder">
					<input type="text" placeholder={ trans("print_contact_address_city_placeholder") } value={state.city} id="city" aria-required={!withHelp} aria-invalid={state.cityError} onChange={event => updateState({ city: event.target.value })} />
					{ state.cityError && <span className="error" role="tooltip" id="id-city">{ trans("print_city_error") }</span> }
				</span>
			</div>
			<div className="form-line">
				<label className="caption" htmlFor="zip">{ trans("print_contact_address_zip") }</label>
				<span className="input-holder">
					<input type="text" placeholder={ trans("print_contact_address_zip_placeholder") } value={state.zipCode} id="zip" aria-required={!withHelp} aria-invalid={state.zipCodeError} onChange={event => updateState({ zipCode: event.target.value })} />
					{ state.zipCodeError && <span className="error" role="tooltip" id="id-zip">{ trans("print_zip_error") }</span> }
				</span>
			</div>
			<div className="form-line">
				<label className="caption" htmlFor="state">{ trans("print_contact_address_state") }</label>
				<span className="input-holder">
					<input type="text" placeholder={ trans("print_contact_address_state_placeholder") } value={state.country} id="state" aria-required={!withHelp} aria-invalid={state.countryError} autoComplete="off" onChange={event => updateState({ country: event.target.value })} />
					{ state.countryError && <span className="error" role="tooltip" id="id-state">{ trans("print_state_error") }</span> }
				</span>
			</div>
		</div>
		<a className="gdpr" href={ getGDPRLink(app.lang) } target="_blank" rel="noreferrer">{ trans("print_contact_additional_gdpr") }</a>
		<button className="step-back" role="link" type="button" onClick={onBack}>
			<svg x="0px" y="0px" viewBox="0 0 14.2 24" className="icon icon-back">
				<path d="M0,12L12.1,0l2,2L4.1,12l10.1,10l-2,2L0,12z"></path>
			</svg>
			<span>{ trans("print_contact_additional_back") }</span>
		</button>
		<button className="step-forward main-print-button" type="button" onClick={beforeSend} disabled={state.wasSend}>
			{ withHelp ? trans("print_contact_additional_send_help") : trans("print_contact_additional_send") }
		</button>
		<p className={`send-info${state.sendInfoError ? " error" : ""}`}>
			{ state.sendInfo }
		</p>
	</div>;
}
