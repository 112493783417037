/* eslint-disable no-magic-numbers */
export function getZoomData(poi: any): any {
	return {
		ward: 13,
		quar: 13,
		osmm: 12,
		muni: 12,
		dist: 9,
		area: () => poi.typeId === "9" || poi.typeId === "12" ? 16 : 8,
		osma: 8,
		regi: 6,
		coun: 3,
		lvl2: 3,
		osms: 14,
		osml: 14,
	};
}

export function buildGeometry(geom) {
	const { SMap, JAK } = window;

	if (!geom || !geom.type) {
		return {
			coords: [],
			geometries: [],
		};
	}

	if (geom.type.indexOf("polygon") > -1) {
		geom.style = {
			width: 3,
			opacity: 1,
			style: 0,
			color: "red",
			outlineWidth: 3,
			outlineStyle: JAK.Vector.STYLE_SOLID,
			outlineColor: "white",
			outlineOpacity: 1,
		};
	}

	let coords = [];
	const geometries = [];

	geom.data.forEach(data => {
		const coor = SMap.Coords.stringToCoords(data);
		const geometry = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, null, coor, geom.style);

		coords = coords.concat(coor);
		geometries.push(geometry);
	});

	return {
		coords,
		geometries,
	};
}

export function computeZoom(poi) {
	// mapovani zoomu podle source
	const zoomData = getZoomData(poi);

	// doplnime lvl
	for (let ind = 1; ind <= 11; ind++) {
		if (ind === 2) {
			continue;
		}

		zoomData[`lvl${ind}`] = 14;
	}

	// zoom data podle source; def. zoom 17
	const zoom = zoomData[poi.source === "osm" && poi.origin ? poi.origin : poi.source] || 17;

	return typeof zoom === "number" ? zoom : zoom();
}

export function countBBox(geometries: Array<any>): {
	x: number;
	y: number;
	width: number;
	height: number;
} {
	const bbox = {
		x: undefined,
		y: undefined,
		x2: undefined,
		y2: undefined,
	};

	geometries.forEach(geometry => {
		geometry.countBBox();

		const box = geometry.getBBox();

		bbox.x = bbox.x ? Math.min(bbox.x, box.x) : box.x;
		bbox.y = bbox.y ? Math.min(bbox.y, box.y) : box.y;
		bbox.x2 = bbox.x2 ? Math.max(bbox.x2, box.x + box.width) : box.x + box.width;
		bbox.y2 = bbox.y2 ? Math.max(bbox.y2, box.y + box.height) : box.y + box.height;
	});

	return {
		x: bbox.x || 0,
		y: bbox.y || 0,
		width: (bbox.x2 || 0) - (bbox.x || 0),
		height: (bbox.y2 || 0) - (bbox.y || 0),
	};
}
