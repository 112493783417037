/* eslint-disable no-magic-numbers */
/* eslint-disable no-invalid-this */
/* eslint-disable func-names */
import { getBBoxFromName, getTileName, bboxToPx, getTileZoom, getGrid } from "~/utils/tile";
import { MIN_GRID_ZOOM } from "~/const";

export function getLayerHAP() {
	const { SMap } = window;

	/**
	 * Zatím není v ES6 - extend.
	 *
	 * @class Vrstva s blind-friendly obdelniky
	 * @signal area-click
	 */
	const LayerHAP = function (id: string) {
		SMap.Layer.Canvas.call(this, id, SMap.LAYER_TILE);

		this._fontSizes = {
			8: 30,
			9: 60,
			10: 120,
			11: 240,
			12: 50,
			13: 100,
			14: 200,
			15: 10,
			16: 20,
			17: 40,
			18: 80,
			19: 120,
		};
		this._enable = false;
		this._tileZoom = 0;
		this._lastTileZoom = 0;
		this._areas = {};
	};

	LayerHAP.prototype = {
		...SMap.Layer.prototype,
		...SMap.Layer.Canvas.prototype,
	};

	LayerHAP.prototype.getAreas = function () {
		return this._areas;
	};

	LayerHAP.prototype.zoomStopTest = function () {
		return this._tileZoom !== this._lastTileZoom;
	};

	LayerHAP.prototype.clear = function () {
		this._areas = {};
		this.redraw(true);
	};

	LayerHAP.prototype._toogleAreaName = function (tileName) {
		if (this.getMap().getZoom() < MIN_GRID_ZOOM) {
			return;
		}

		if (tileName in this._areas) {
			delete this._areas[tileName];
		} else {
			const bbox = getBBoxFromName(tileName);

			this._areas[tileName] = bbox;
		}

		this._lastTileZoom = this._tileZoom;
		this.redraw(true);
	};

	LayerHAP.prototype.toggleAreaName = function (tileName) {
		this._toogleAreaName(tileName);
	};

	LayerHAP.prototype.toggleAreaCoords = function (coords) {
		const merc = coords.toMercator();
		const tileName = getTileName(merc[0], merc[1], this._tileZoom);

		this._toogleAreaName(tileName);
	};

	LayerHAP.prototype._drawTile = function (key, bbox) {
		const pxObj = bboxToPx(bbox, this.getMap());
		// pulky sirek a vysek
		const w2 = Math.round(pxObj.width / 2);
		const h2 = Math.round(pxObj.height / 2);

		// velikost fontu
		this._context.fillStyle = "rgba(53, 162, 9, 0.3)";
		this._context.fillRect(pxObj.x, pxObj.y, pxObj.width, pxObj.height);
		this._context.font = `${this._fontSizes[this.getMap().getZoom()]}px sans-serif`;
		this._context.fillStyle = "rgba(255, 255, 255, 0.7)";
		this._context.fillText(key, pxObj.x + w2, pxObj.y + h2);
	};

	LayerHAP.prototype.redraw = function (full) {
		SMap.Layer.Canvas.prototype.redraw.call(this, full);

		if (this.getMap().getZoom() < MIN_GRID_ZOOM) {
			this._enable = false;

			return;
		}

		const map = this.getMap();
		const zoom = map.getZoom();

		this._enable = true;
		this._tileZoom = getTileZoom(zoom);

		/* obdelniky */
		this._context.textAlign = "center";
		this._context.textBaseline = "middle";

		Object.keys(this._areas).forEach(key => {
			this._drawTile(key, this._areas[key]);
		});

		this._context.beginPath();
		this._context.strokeStyle = "rgba(53, 162, 9, 0.8)";
		this._context.lineWidth = 3;

		// mrizka
		const gridObj = getGrid(map);

		// svisle cary
		gridObj.cols.forEach(col => {
			this._context.moveTo(col, 0);
			this._context.lineTo(col, this._context.canvas.height);
		});

		// vodorovne cary
		gridObj.rows.forEach(row => {
			this._context.moveTo(0, row);
			this._context.lineTo(this._context.canvas.width, row);
		});

		this._context.stroke();
	};

	LayerHAP.prototype.isEnabled = function () {
		return this._enable;
	};

	return LayerHAP;
}
