import { create } from 'zustand';

import { IUserFormData } from "~/interfaces";
import { USER_FORM_STATES, EMPTY_INFO } from "~/const";

export interface IApp {
	lang: string;
	showLegend: boolean;
	showThankYou: boolean;
	userFormState: number;
	searchQuery: string;
	selectedTiles: Array<string>;
	userFormData: IUserFormData;
	isResponsive: boolean;
	isLayoutMode: boolean;
}

function getInitFormState() {
	// zobrazeni free formulare
	if (location.pathname === "/free") {
		return USER_FORM_STATES.HELP;
	}

	return USER_FORM_STATES.NONE;
}

interface IAppStore {
	app: IApp;
	setLang: (lang: string) => void;
	showLegend: (showLegend: boolean) => void;
	showThankYou: (showThankYou: boolean) => void;
	setSearchQuery: (searchQuery) => void;
	setUserForm: (formState: number, userData?: IUserFormData) => void;
	setSelectedTiles: (tiles: Array<string>) => void;
	setResponsive: (isResponsive: boolean) => void;
	setLayout: (isLayoutMode: boolean) => void;
}

export const appStore = create<IAppStore>(set => ({
	app: {
		lang: "",
		showLegend: false,
		showThankYou: false,
		userFormState: getInitFormState(),
		searchQuery: "",
		selectedTiles: [],
		userFormData: {
			...EMPTY_INFO,
		},
		isResponsive: false,
		isLayoutMode: true,
	},
	setLang: lang => set(state => ({
		app: {
			...state.app,
			lang,
		},
	})),
	showLegend: showLegend => set(state => ({
		app: {
			...state.app,
			showLegend,
		},
	})),
	showThankYou: showThankYou => set(state => ({
		app: {
			...state.app,
			userFormState: USER_FORM_STATES.NONE,
			showThankYou,
		},
	})),
	setSearchQuery: searchQuery => set(state => ({
		app: {
			...state.app,
			showLegend: false,
			searchQuery,
		},
	})),
	setUserForm: (formState, userData) => set(state => ({
		app: {
			...state.app,
			userFormState: formState,
			userFormData: {
				...EMPTY_INFO,
				...userData,
			},
		},
	})),
	setSelectedTiles: (tiles: Array<string>) => set(state => ({
		app: {
			...state.app,
			selectedTiles: tiles,
		},
	})),
	setResponsive: (isResponsive: boolean) => set(state => ({
		app: {
			...state.app,
			isResponsive,
		},
	})),
	setLayout: (isLayoutMode: boolean) => set(state => ({
		app: {
			...state.app,
			isLayoutMode,
		},
	})),
}));
