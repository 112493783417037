/* eslint-disable no-magic-numbers */
import { useRef, useEffect } from "react";

import { getLayerHAP } from "~/map/layerHAP";
import { getMarker } from "~/map/marker";
import { getControlZoom } from "~/map/controlZoom";
import { DEV_PATHS, PATHS, HAPTIC_TILE_ZOOMS } from "~/const";
import { isLocalDev, trans, updateUrlFromData } from "~/utils/utils";
import { countBBox } from "~/utils/map";
import { getDetailData } from "~/providers/rpc";
import { appStore } from "~/stores/app";
import { positionStore } from "~/stores/position";
import { addMapListener, setMapRef, EVENTS } from "~/stores/map";

import "./style.less";

interface IMap {
	isVisible: boolean;
}

interface IMapDataRef {
	detailShow: boolean;
	geometries: Array<any>;
	marker: any;
	layerBase: any;
	layerHAP: any;
	layerTile: any;
	layerMarker: any;
	layerGeometry: any;
}

export default function Map({
	isVisible = true,
}: IMap) {
	const { position, setPosition } = positionStore(positonState => ({
		position: positonState.position,
		setPosition: positonState.setPosition,
	}));
	const { SMap } = window;
	const LayerHAP = getLayerHAP();
	const mapEl = useRef<HTMLDivElement>(null);
	const map = useRef<any>(null);
	const mapData = useRef<IMapDataRef>({
		detailShow: false,
		geometries: [],
		marker: getMarker(),
		layerBase: null,
		layerHAP: new LayerHAP("haptic"),
		layerTile: new SMap.Layer.Tile(null, PATHS.LAYER),
		layerMarker: new SMap.Layer.Marker(),
		layerGeometry: new SMap.Layer.Geometry(),
	});

	function syncLayers() {
		const zoom = map.current.getZoom();

		if (HAPTIC_TILE_ZOOMS.indexOf(zoom) === -1) {
			mapData.current.layerBase.enable();
			mapData.current.layerTile.disable();
		} else {
			mapData.current.layerBase.disable();
			mapData.current.layerTile.enable();
		}
	}

	function setZoom(zoom: number) {
		map.current.setZoom(zoom);
		syncLayers();
	}

	function sendTilesChange() {
		const selTiles = Object.keys(mapData.current.layerHAP.getAreas());

		appStore.getState().setSelectedTiles(selTiles);
	}

	function mapClick(event: any) {
		const coords = SMap.Coords.fromEvent(event.data.event, map.current);

		mapData.current.layerHAP.toggleAreaCoords(coords);
		sendTilesChange();
	}

	function mapRedraw() {
		const wgs84 = map.current.getCenter().toWGS84();
		const zoom = map.current.getZoom();

		setPosition(wgs84[0], wgs84[1], zoom);
		updateUrlFromData({
			x: wgs84[0],
			y: wgs84[1],
			z: zoom,
		}, appStore.getState().app.lang);
	}

	function setMarkerAndAreaVisibility(hide?: boolean) {
		if (!mapData.current.detailShow) {
			return;
		}

		const bbox = countBBox(mapData.current.geometries);
		const bboxArea = bbox.width * bbox.height;
		const zoom = map.current.getZoom();

		if (hide) {
			mapData.current.layerMarker.removeAll();
			mapData.current.layerGeometry.disable();
		} else if (bbox.width !== 0 && bbox.height !== 0) {
			const size = Math.max(bbox.width, bbox.height);

			// zobrazit znacku i geometrii
			if (zoom > 15 && bboxArea < 30 * 30 && size < 30) {
				// mala area - marker
				mapData.current.layerMarker.addMarker(mapData.current.marker);
				mapData.current.layerGeometry.enable();
			// testovaci oblast vuci konstante 70x70 (sirka a vyska); a zoom mensi jak 16
			} else if (zoom < 16 && bboxArea < 70 * 70 && size < 70) {
				// mala area - marker
				mapData.current.layerMarker.addMarker(mapData.current.marker);
				mapData.current.layerGeometry.disable();
			} else {
				// velka area - zobrazime oblast
				mapData.current.layerMarker.removeAll();
				mapData.current.layerGeometry.enable();
			}
		} else {
			const size = Math.max(bbox.width, bbox.height);

			if (zoom > 15 && size < 30) {
				// mala area - marker
				mapData.current.layerMarker.addMarker(mapData.current.marker);
				mapData.current.layerGeometry.enable();
			// testovaci oblast vuci konstante 70x70 (sirka a vyska); a zoom mensi jak 16
			} else if (zoom < 16 && size < 70) {
				// mala area - marker
				mapData.current.layerMarker.addMarker(mapData.current.marker);
				mapData.current.layerGeometry.disable();
			} else {
				// velka area - zobrazime oblast
				mapData.current.layerMarker.removeAll();
				mapData.current.layerGeometry.enable();
			}
		}
	}

	async function loadDetail(source: string, id: number) {
		try {
			const data = await getDetailData(source, id, map.current);
			const center = SMap.Coords.fromWGS84(data.x, data.y);

			// reset
			mapData.current.layerMarker.removeAll();
			mapData.current.layerGeometry.removeAll();
			mapData.current.geometries = data.geometries;
			mapData.current.detailShow = true;
			mapData.current.marker.setCoords(center);

			if (data.isGeom) {
				data.geometries.forEach(geom => mapData.current.layerGeometry.addGeometry(geom));
			} else {
				mapData.current.layerMarker.addMarker(mapData.current.marker);
			}

			map.current.setCenterZoom(center, data.z);
			syncLayers();
		} catch (exc) {
			/* eslint-disable no-console */
			console.log(exc);
		}
	}

	function buildMapControls() {
		map.current.addControl(new SMap.Control.Mouse(SMap.MOUSE_PAN | SMap.MOUSE_WHEEL | SMap.MOUSE_ZOOM));
		map.current.addControl(new SMap.Control.Keyboard(SMap.KB_PAN | SMap.KB_ZOOM));
		map.current.addControl(new SMap.Control.Selection(2));
		map.current.addControl(new SMap.Control.ZoomNotification());

		/* zoom */
		const zoom = new SMap.Control.Zoom({}, { showZoomMenu: false });

		map.current.addControl(zoom, { right: "19px", top: "17px" });
		zoom._dom.plus.title = trans("map_zoom_plus");
		zoom._dom.minus.title = trans("map_zoom_minus");
		/* pravy padding */
		map.current.setPadding("right", map.current.getPadding("right") + 73 + 8 + 2);
		map.current.setCursor("move");

		const ControlZoom = getControlZoom(newZoom => {
			setZoom(newZoom);
			// pri zmene control zoomu smazeme dlazdice
			mapData.current.layerHAP.clear();
			appStore.getState().setSelectedTiles([]);
		});
		const controlZoom = new ControlZoom();

		map.current.addControl(controlZoom);
	}

	function build() {
		map.current.setZoomRange(3, 19);
		mapData.current.layerBase = map.current.addDefaultLayer(SMap.DEF_BASE);
		map.current.addLayer(mapData.current.layerTile);
		map.current.addLayer(mapData.current.layerHAP).enable();
		map.current.addLayer(mapData.current.layerMarker);
		map.current.addLayer(mapData.current.layerGeometry);
		syncLayers();
		/* eslint-disable no-invalid-this */
		map.current.getSignals().addListener(this, "map-redraw", () => {
			mapRedraw();
		});
		map.current.getSignals().addListener(this, "zoom-start", () => {
			setMarkerAndAreaVisibility(true);
		});
		map.current.getSignals().addListener(this, "zoom-stop", () => {
			setMarkerAndAreaVisibility();
			syncLayers();

			if (mapData.current.layerHAP.zoomStopTest() || !mapData.current.layerHAP.isEnabled()) {
				// zmena
				mapData.current.layerHAP.clear();
				appStore.getState().setSelectedTiles([]);
			}
		});
		map.current.getSignals().addListener(this, "map-click", event => {
			mapClick(event);
		});
		buildMapControls();
		// listeners
		addMapListener(EVENTS.SET_POI, setPoiData => {
			if (setPoiData.source && setPoiData.id) {
				mapData.current.layerMarker.enable();
				mapData.current.layerGeometry.enable();
				loadDetail(setPoiData.source, setPoiData.id);
			} else {
				// reset
				mapData.current.detailShow = false;
				mapData.current.geometries = [];
				mapData.current.layerMarker.removeAll();
				mapData.current.layerGeometry.removeAll();
				mapData.current.layerMarker.disable();
				mapData.current.layerGeometry.disable();
			}
		});
		addMapListener(EVENTS.SET_ZOOM, zoomData => {
			setZoom(zoomData.zoom);
		});
		addMapListener(EVENTS.CHANGE_TILE, tileData => {
			mapData.current.layerHAP.toggleAreaName(tileData.tile);
			sendTilesChange();
		});
		addMapListener(EVENTS.CLEAR_TILES, () => {
			mapData.current.layerHAP.clear();
			appStore.getState().setSelectedTiles([]);
		});
		addMapListener(EVENTS.SYNC_PORT, () => {
			map.current.syncPort();
		});

		// poiagregator
		const poiAgg = new SMap.POIServer.FRPC(isLocalDev() ? DEV_PATHS.POIAGG : PATHS.POIAGG, { zoomCorrection: 0 });

		poiAgg.setOwner(map.current);
		poiAgg.getPOIParams();
	}

	useEffect(() => {
		if (mapEl.current && !map.current) {
			const center = SMap.Coords.fromWGS84(position.x, position.y);
			const smapInstance = new SMap(mapEl.current, center, position.z);

			map.current = smapInstance;
			setMapRef(map.current);

			build();
		}
	}, [mapEl.current]);

	return <div className="map-container" ref={mapEl} style={{ visibility: isVisible ? "visible" : "hidden" }}></div>;
}
