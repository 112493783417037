import { ReactNode } from "react";
import { trans } from "~/utils/utils";

// styly
import "./style.less";

interface IContent {
	children: ReactNode;
}

export default function Content({
	children = null,
}: IContent) {
	return <main role="main" aria-labelledby="id-main-content">
		<h6 id="id-main-content" className="blind">{ trans("blind.main") }</h6>
		{ children }
	</main>;
}
