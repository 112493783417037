import { trans } from "~/utils/utils";

interface IThankYou {
	withHelp: boolean;
	onClose?: () => void;
}

export default function ThankYou({
	withHelp = false,
	onClose = () => {},
}: IThankYou) {
	return <div className="user-form">
		<h2>{ withHelp ? trans("print_order_last_step_title_help") : trans("print_order_last_step_title") }</h2>
		<p>
			{ withHelp ? trans("print_order_last_step_desc_help") : trans("print_order_last_step_desc") }
		</p>
		<button type="button" className="main-print-button" onClick={onClose}>
			{ trans("print_order_last_step_back") }
		</button>
	</div>;
}
