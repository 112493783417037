import { useEffect, useRef } from "react";

import PromoVariant from "~/components/PromoVariant";
import { appStore } from "~/stores/app";
import { getYoutubeId } from "~/utils/lang-data";

// styly
import "./style.less";

interface IPromo {
	visible: boolean;
}

export default function Promo({
	visible = true,
}: IPromo) {
	const { app } = appStore(appState => ({
		app: appState.app,
	}));
	const youtubePlayer = useRef<any>(null);
	const youtubeContID = "youtubeCont";

	async function loadYoutube() {
		try {
			await window.youtubeProm;

			youtubePlayer.current = new window.YT.Player(youtubeContID, {
				width: '340',
				height: '240',
				videoId: getYoutubeId(app.lang),
			});
		} catch (exc) {
			/* eslint-disable no-console */
			console.log(exc);
		}
	}

	useEffect(() => {
		loadYoutube();
	}, []);

	return <div className="promo" style={{ display: visible ? "block" : "none" }}>
		<img src="/static/img/promo.jpg" alt="promo" className="promo-img" />
		<PromoVariant variant="1" />
		<PromoVariant variant="2" />
		<PromoVariant variant="3" />
		<PromoVariant variant="4" />
		<PromoVariant variant="5" />
		<PromoVariant variant="6" />
		<div id={youtubeContID}></div>
	</div>;
}
