let mapRef = null;
const listeners: Array<{ name: string; callback: (data: any) => void; }> = [];

export function setMapRef(map: any) {
	mapRef = map;
}

export function getMapRef() {
	return mapRef;
}

export const EVENTS = {
	SET_POI: "SET_POI",
	SET_ZOOM: "SET_ZOOM",
	CHANGE_TILE: "CHANGE_TILE",
	CLEAR_TILES: "CLEAR_TILES",
	SYNC_PORT: "SYNC_PORT",
};

export function addMapListener(name: string, callback: (data: any) => void) {
	listeners.push({
		name,
		callback,
	});
}

export function makeMapEvent(name: string, data: any) {
	listeners.filter(listener => listener.name === name).forEach(listener => listener.callback(data));
}

export function setPOI(source: string, id: number) {
	makeMapEvent(EVENTS.SET_POI, {
		source,
		id,
	});
}

export function setZoom(zoom: number) {
	makeMapEvent(EVENTS.SET_ZOOM, {
		zoom,
	});
}

export function changeTile(tile: string) {
	makeMapEvent(EVENTS.CHANGE_TILE, {
		tile,
	});
}

export function clearTiles() {
	makeMapEvent(EVENTS.CLEAR_TILES, {});
}

export function syncPort() {
	makeMapEvent(EVENTS.SYNC_PORT, {});
}
