import { useEffect, useState } from "react";

import { ISearchItem } from "~/interfaces";
import { setPOI, getMapRef } from "~/stores/map";
import { trans } from "~/utils/utils";
import { getSearchFromQuery } from "~/providers/rpc";
import { appStore } from "~/stores/app";

// styly
import "./style.less";

interface IState {
	msg: string;
	items: Array<ISearchItem>;
}

export default function SearchResults() {
	const { app } = appStore(appState => ({
		app: appState.app,
	}));
	const [state, setState] = useState<IState>({
		msg: trans("search_loading"),
		items: [],
	});

	function openItem(item: ISearchItem) {
		setPOI(item.source, item.id);
	}

	async function makeSearch() {
		setState(prev => ({
			...prev,
			items: [],
			msg: trans("search_loading"),
		}));

		try {
			const results = await getSearchFromQuery(app.searchQuery, app.lang, getMapRef());

			setState(prev => ({
				...prev,
				msg: results.length > 0 ? "" : trans("search_empty"),
				items: results,
			}));
		} catch (exc) {
			/* eslint-disable no-console */
			console.log(exc);
			setState(prev => ({
				...prev,
				msg: trans("search_error"),
			}));
		}
	}

	useEffect(() => {
		if (app.searchQuery) {
			makeSearch();
		}
	}, [app.searchQuery]);

	return <ol className="search-results">
		{ state.msg && <p className="search-msg">
			{ state.msg }
		</p> }
		{ state.items.map(item => <li key={`${item.source}_${item.id}`} onClick={() => openItem(item)}>
			<div className="inner">
				<div className="icon" style={{ backgroundImage: `url(${item.iconData})` }}></div>
				<div className="content">
					<h3>{ item.title }</h3>
					{ item.titleVars.specification && <p className="specification">{ item.titleVars.specification }</p> }
					{ item.titleVars.locationMain1 && <p className="location">{ item.titleVars.locationMain1 }</p> }
				</div>
			</div>
		</li>) }
	</ol>;
}
