/* eslint-disable no-magic-numbers */
/* eslint-disable id-length */
/* eslint-disable no-constant-condition */
import { getMapRef } from "~/stores/map";

const ROWS = 8;
const COLS = 18;
const EQUATOR_LEN = 2 * Math.PI * 6378137;
const BASE_X = -EQUATOR_LEN / 2;
const TILE_SIZE = {
	WIDTH: 1200,
	HEIGHT: 1700,
};
const RES = {
	9: EQUATOR_LEN / (TILE_SIZE.WIDTH * 6 * COLS),
	11: EQUATOR_LEN / (TILE_SIZE.WIDTH * 24 * COLS),
	14: EQUATOR_LEN / (TILE_SIZE.WIDTH * 194 * COLS),
	19: EQUATOR_LEN / (TILE_SIZE.WIDTH * 6214 * COLS),
};
const PRECS = {
	9: 1,
	11: 2,
	14: 3,
	19: 4,
};
const A_VALUE = "a".charCodeAt(0);
// omezeni rekurze
const MAX_STEPS = 150;

/**
 * Ziskani zoomu dlazdice podle zoomu mapy.
 *
 * @param  {Number} zoom
 * @return {Number}
 */
export function getTileZoom(zoom: number): number {
	if (zoom <= 11) {
		return 11;
	} else if (zoom > 11 && zoom <= 14) {
		return 14;
	}

	return 19;
}

/**
 * Ziskani pomocnych promennych dle zoomu.
 *
 * @param  {Number} zoom Mapa nebo {9,11,14,19}
 * @return {Object}
 */
function getZoomValues(zoom: number) {
	const tileZoom = getTileZoom(zoom);
	const res = RES[tileZoom];
	const tilesX = Math.round(EQUATOR_LEN / (res * TILE_SIZE.WIDTH) / COLS);
	const tilesY = Math.floor(EQUATOR_LEN * 0.8 / ROWS / (res * TILE_SIZE.HEIGHT));
	const baseY = tilesY * TILE_SIZE.HEIGHT * res * (ROWS / 2);
	const sizeX = res * TILE_SIZE.WIDTH;
	const sizeY = res * TILE_SIZE.HEIGHT;

	return {
		tileZoom,
		tilesX,
		tilesY,
		baseY,
		sizeX,
		sizeY,
	};
}

/**
 * Lpad vyplneny nulama.
 *
 * @param  {Number} value Vstupni hodnota
 * @param  {Number} count Pocet mist
 * @return {String}
 */
function zeroLPad(value = 0, count = 0): string {
	const strValue = value.toString();
	let lpad = "";

	if (strValue.length < count) {
		const diff = count - strValue.length;

		for (let ind = 0; ind < diff; ind++) {
			lpad += "0";
		}
	}

	return `${lpad}${strValue}`;
}


/**
 * Ziskani velikosti dlazdice v mercatoru [m].
 *
 * @param  {Number} zoom Mapa nebo {9,11,14,19}
 * @return {Object}
 */
export function getTileSize(zoom) {
	const zv = getZoomValues(zoom);

	return {
		width: zv.sizeX,
		height: zv.sizeY,
	};
}

/**
 * Ziskani nazvu dlazdice.
 *
 * @param  {Number} col Sloupec
 * @param  {Number} row Radek
 * @param  {Number} sx Poradi ve sloupci
 * @param  {Number} sy Poradi v radku
 * @param  {Number} tileZoom Zoom v mnozime {9, 11, 14, 19}
 * @return {String}
*/
function _getTileName(col, row, sx, sy, tileZoom): string {
	const precs = PRECS[tileZoom];

	return `${String.fromCharCode(A_VALUE + col)}${zeroLPad(sx, precs)}_${String.fromCharCode(A_VALUE + COLS + row)}${zeroLPad(sy, precs)}`;
}

/**
 * Ziskani nazvu dlazdice pomoci souradnic a zoomu na mape.
 *
 * @param  {Number} x Mercator souradnice
 * @param  {Number} y Mercator souradnice
 * @param  {Number} zoom Mapa nebo {9,11,14,19}
 * @return {String}
 */
export function getTileName(x, y, zoom) {
	const zv = getZoomValues(zoom);
	const tx = Math.floor((x - BASE_X) / zv.sizeX);
	const ty = Math.ceil((y - zv.baseY) / -zv.sizeY) - 1;
	const sx = tx % zv.tilesX;
	const sy = ty % zv.tilesY;
	const col = tx / zv.tilesX;
	const row = ty / zv.tilesY;

	return _getTileName(col, row, sx, sy, zv.tileZoom);
}

/**
 * Ziskani nazvu dlazdice pomoci pozide dlazdice a zoomu.
 *
 * @param  {Number} x Pozice dlazdice
 * @param  {Number} y Pozice dlazdice
 * @param  {Number} zoom Mapa nebo {9,11,14,19}
 * @return {String}
 */
export function getTileNameFromPos(x, y, zoom) {
	const zv = getZoomValues(zoom);
	const sx = x % zv.tilesX;
	const sy = y % zv.tilesY;
	const col = x / zv.tilesX;
	const row = y / zv.tilesY;

	return _getTileName(col, row, sx, sy, zv.tileZoom);
}

/**
 * Dekoduje jmeno dlazdice na jeji souradnice.
 *
 * @param  {String} name
 * @return {Object}
 */
export function getPosFromName(name = "") {
	const parts = name.split("_");

	if (parts.length !== 2) {
		return null;
	}

	const x = parts[0];
	const y = parts[1];
	const col = x[0].charCodeAt(0) - A_VALUE;
	const row = y[0].charCodeAt(0) - A_VALUE - COLS;
	const zoomLen = x.length - 1;
	let zoom = null;

	Object.keys(PRECS).every(zoomKey => {
		const value = PRECS[zoomKey];

		if (value === zoomLen) {
			zoom = parseFloat(zoomKey);

			return false;
		}

		return true;
	});

	if (zoom === null) {
		return null;
	}

	const zv = getZoomValues(zoom);
	const tx = (zv.tilesX * col) + parseFloat(x.slice(1));
	const ty = (zv.tilesY * row) + parseFloat(y.slice(1));

	return {
		x: tx,
		y: ty,
		mercX: BASE_X + (tx * zv.sizeX) + (zv.sizeX / 2),
		mercY: zv.baseY - (zv.sizeY * (ty + 1)) + (zv.sizeY / 2),
		zoom,
	};
}

/**
 * Dekoduje jmeno dlazdice na jeji bbox.
 *
 * @param  {String} name
 * @return {Array} [x, y, x + width, y + height]
 */
export function getBBoxFromName(name = ""): Array<number> {
	const pos = getPosFromName(name);

	if (!pos) {
		return null;
	}

	const zv = getZoomValues(pos.zoom);
	// mercator
	const x = BASE_X + (pos.x * zv.sizeX);
	const y = zv.baseY - (pos.y * zv.sizeY);

	return [
		x,
		y,
		x + zv.sizeX,
		y - zv.sizeY,
	];
}

/**
 * Prepocte souradnice bbox na px souradnice viewportu (<0;0> v levem hornim rohu)
 *
 * @param  {Array} bbox
 * @param  {SMap} map
 * @return {Object}
 */
export function bboxToPx(bbox, map) {
	const port = map.getSize();
	const portHalf = new window.SMap.Pixel(port.x / 2, port.y / 2);
	const lt = window.SMap.Coords.fromMercator(bbox[0], bbox[1]).toPixel(map).plus(portHalf);
	const rb = window.SMap.Coords.fromMercator(bbox[2], bbox[3]).toPixel(map).plus(portHalf);
	const width = Math.abs(rb.x - lt.x);
	const height = Math.abs(rb.y - lt.y);

	return {
		x: lt.x,
		y: lt.y,
		width,
		height,
	};
}

/**
 * Ziskani mrizku pro vykresleni dlazdic.
 * Vystupem objekt s definici radku a sloupcu, kde jsou pouze casti souradnic.
 *
 * @param  {SMap} map
 * @return {Object} rows {Array}, cols {Array}
 */
export function getGrid(map) {
	const port = map.getSize();
	const portHalf = new window.SMap.Pixel(port.x/2, port.y/2);

	// souradnice
	const leftTop = new window.SMap.Pixel(-portHalf.x, -portHalf.y).toCoords(map).toMercator();
	const rightTop = new window.SMap.Pixel(portHalf.x, -portHalf.y).toCoords(map).toMercator();
	const leftBottom = new window.SMap.Pixel(-portHalf.x, portHalf.y).toCoords(map).toMercator();
	const tileSize = getTileSize(map.getZoom());
	let firstX = Math.floor(leftTop[0] / tileSize.width) * tileSize.width;
	let firstY = Math.floor(leftBottom[1] / tileSize.height) * tileSize.height;
	const cols = [];
	const rows = [];
	let step = 0;

	while (true) {
		firstX += step === 0 ? 0 : tileSize.width;

		const px = window.SMap.Coords.fromMercator(firstX, leftTop[1]).toPixel(map).plus(portHalf);

		cols.push(px.x);
		step++;

		const test = rightTop[0] > 0 ? firstX > rightTop[0] : firstX < Math.abs(rightTop[0]);

		if (test || step > MAX_STEPS) {
			break;
		}
	}

	step = 0;

	while (true) {
		firstY += step === 0 ? 0 : tileSize.height;

		const px = window.SMap.Coords.fromMercator(leftBottom[1], firstY).toPixel(map).plus(portHalf);

		rows.push(px.y);
		step++;

		const test = leftTop[1] > 0 ? firstY > leftTop[1] : firstY < Math.abs(leftTop[1]);

		if (test || step > MAX_STEPS) {
			break;
		}
	}

	return {
		cols,
		rows,
	};
}

/**
 * Ziskani dlazdic, ktere oblokupuji bbox vstupnich dlazdic.
 *
 * @param  {Array} tiles Vstupni pole s ID dlazdic
 * @param  {Number} zoom Mapa nebo {9,11,14,19}
 * @param  {Object} [increase] objekt pro rozsireni bboxu
 * @return {Array} 2d pole
 */
export function getTilesSurround(tiles, zoom, increase = {
	minX: -1,
	maxX: 1,
	minY: -1,
	maxY: 1,
}) {
	const zv = getZoomValues(zoom);
	let minX = zv.tilesX * COLS;
	let minY = zv.tilesY * ROWS;
	let maxX = null;
	let maxY = null;

	tiles.forEach(tileName => {
		const decPos = getPosFromName(tileName);

		minX = Math.min(decPos.x, minX);
		minY = Math.min(decPos.y, minY);
		maxX = Math.max(decPos.x, maxX);
		maxY = Math.max(decPos.y, maxY);
	});

	const rows = [];

	// rozsireni o 1 do vsech stran (by default)
	minX += increase.minX || 0;
	maxX += increase.maxX || 0;
	minY += increase.minY || 0;
	maxY += increase.maxY || 0;

	for (let indY = minY; indY <= maxY; indY++) {
		const cels = [];

		for (let indX = minX; indX <= maxX; indX++) {
			cels.push(getTileNameFromPos(indX, indY, zv.tileZoom));
		}

		rows.push(cels);
	}

	return rows;
}

export function getTableItems(selectedTiles: any, zoom: number, increase?: any) {
	const zoomSize = getTileZoom(zoom);
	let areas = [];

	if (selectedTiles.length) {
		areas = selectedTiles;
	} else {
		const center = new window.SMap.Pixel(0, 0).toCoords(getMapRef()).toMercator();
		const size = getTileSize(zoomSize);
		const x = Math.floor(center[0] / size.width) * size.width;
		const y = Math.floor(center[1] / size.height) * size.height;

		areas = [
			getTileName(x, y, zoomSize),
		];
	}

	return getTilesSurround(areas, zoomSize, increase);
}
