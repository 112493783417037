import { trans, updateUrlFromData, getLangs } from "~/utils/utils";
import LangSwitcher from "~/components/LangSwitcher";
import SearchForm from "~/components/SearchForm";
import { USER_FORM_STATES } from "~/const";
import { appStore } from "~/stores/app";
import { positionStore } from "~/stores/position";
import { setPOI } from "~/stores/map";
import { setCookie } from "~/utils/cookies";
import { ISuggestData } from "~/interfaces";
import { getLogoSrc } from "~/utils/lang-data";

// styly
import "./style.less";

export default function Header() {
	const { app, showLegend } = appStore(appState => ({
		app: appState.app,
		showLegend: appState.showLegend,
	}));
	const { position } = positionStore(posState => ({
		position: posState.position,
	}));

	function onLangChange(langKey: string) {
		// set cookie
		setCookie("lang", langKey, {
			expiration: Infinity,
		});
		updateUrlFromData({
			x: position.x,
			y: position.y,
			z: position.z,
		}, langKey);
		// refresh stranky
		location.reload();
	}

	function backClick() {
		showLegend(false);
	}

	function onSuggest(suggestData: ISuggestData) {
		setPOI(suggestData.data.source, suggestData.data.id);
	}

	return <header role="banner" aria-label={ trans("blind.header") }>
		<h1>
			<a href="/" className={app.showLegend ? "hidden" : ""}>
				<img src={getLogoSrc(app.lang)} alt={ trans("logo_alt") } />
			</a>
			{ app.showLegend && <span className="back" onClick={backClick}>{ trans("common_back") }</span> }
		</h1>
		<LangSwitcher langs={getLangs(app.lang)} onChange={onLangChange} />
		{ app.userFormState === USER_FORM_STATES.NONE && !app.showThankYou && <h2 className="title">{ trans("title") }</h2> }
		<SearchForm onSuggest={onSuggest} hidden={app.showLegend || app.userFormState !== USER_FORM_STATES.NONE || app.showThankYou} />
	</header>;
}
