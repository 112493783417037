import { useState, ChangeEvent } from "react";

import { appStore } from "~/stores/app";
import { trans, startBlobDownload } from "~/utils/utils";
import { getPosFromName } from "~/utils/tile";
import { clearTiles } from "~/stores/map";
import { LIST_ID, USER_FORM_STATES, PRINT_SYNC_ZOOM, HAPTIC_TILE_ZOOMS } from "~/const";
import { IUserFormData } from "~/interfaces";
import { getAPIFetch } from "~/providers/api";

// styly
import "./style.less";

interface IState {
	mapName: string;
	printShortcuts: boolean;
	addInfo: boolean;
	legend: boolean;
	listId: boolean;
	listValue: number;
	downloadInfo: string;
	showError: boolean;
	wasSend: boolean;
}

export default function Print() {
	const [state, setState] = useState<IState>({
		mapName: "",
		printShortcuts: true,
		addInfo: false,
		legend: false,
		listId: true,
		listValue: LIST_ID.LEFT,
		downloadInfo: "",
		showError: false,
		wasSend: false,
	});
	const { app, setUserForm } = appStore(appState => ({
		app: appState.app,
		setUserForm: appState.setUserForm,
	}));

	function shortcutsChange(event: ChangeEvent<HTMLInputElement>) {
		const isChecked = event.target.checked;

		setState(prev => ({
			...prev,
			printShortcuts: isChecked,
			...isChecked
				? {}
				: {
					addInfo: false,
				},
		}));
	}

	function addInfoChange(event: ChangeEvent<HTMLInputElement>) {
		if (state.printShortcuts) {
			setState(prev => ({
				...prev,
				addInfo: event.target.checked,
			}));
		}
	}

	function listChange(event: ChangeEvent<HTMLInputElement>) {
		const isChecked = event.target.checked;

		setState(prev => ({
			...prev,
			listId: isChecked,
			...isChecked && prev.listValue === 0
				? {
					listValue: LIST_ID.LEFT,
				}
				: {},
			...!isChecked && prev.listValue !== 0
				? {
					listValue: 0,
				}
				: {},
		}));
	}

	function radioChange(event: ChangeEvent<HTMLInputElement>) {
		const value = parseFloat(event.target.value);

		setState(prev => ({
			...prev,
			listValue: value,
			...!prev.listId && value !== 0
				? {
					listId: true,
				}
				: {},
		}));
	}

	function getInfo(): IUserFormData {
		return {
			listIds: app.selectedTiles,
			shortcuts: state.printShortcuts,
			maplegend: state.legend,
			mapname: state.mapName,
			pois: state.addInfo,
			...state.listValue === 0
				? {}
				: {
					pos: state.listValue.toString(),
				},
		};
	}

	async function download() {
		const sendData = {
			mode: "download",
			info: {
				...getInfo(),
				lang: app.lang,
			},
			contact: {},
		};

		setState(prev => ({
			...prev,
			downloadInfo: trans("print_download_start"),
			wasSend: true,
			showError: false,
		}));

		try {
			const request = await getAPIFetch(sendData);

			/* eslint-disable no-magic-numbers */
			if (request.status >= 200 && request.status < 300) {
				const blob = await request.blob();
				const fileName = sendData.info.mapname ? sendData.info.mapname.replace(/\s/ug, "_") : "result";

				setState(prev => ({
					...prev,
					downloadInfo: "",
					showError: false,
					wasSend: false,
				}));
				startBlobDownload(blob, `${fileName}.zip`);
			} else {
				setState(prev => ({
					...prev,
					downloadInfo: trans("print_download_error"),
					showError: true,
					wasSend: false,
				}));
			}
		} catch (exc) {
			/* eslint-disable no-console */
			console.log(exc);
			setState(prev => ({
				...prev,
				downloadInfo: trans("print_download_error"),
				showError: true,
				wasSend: false,
			}));
		}
	}

	function goToHelp() {
		setUserForm(USER_FORM_STATES.HELP, getInfo());
	}

	function goToOrder() {
		setUserForm(USER_FORM_STATES.ORDER, getInfo());
	}

	function getTilesTrans() {
		const count = app.selectedTiles.length;
		const zoom = count === 0 ? HAPTIC_TILE_ZOOMS[0] : getPosFromName(app.selectedTiles[0]).zoom;
		const zoomInd = HAPTIC_TILE_ZOOMS.indexOf(zoom);
		const tilesTrans = trans(`print_sync_zoom_${PRINT_SYNC_ZOOM[zoomInd]}`);

		return `${trans("print_sync_2", { count })} ${tilesTrans}`;
	}

	return <div id="print">
		<h2>
			{ getTilesTrans() }
			<button type="button" title={ trans("print_cancel") } onClick={() => clearTiles()}>
				<svg x="0px" y="0px" viewBox="0 0 24 24" className="icon icon-close">
					<path d="M3.6,0.1L0.1,3.6L8.5,12l-8.4,8.4l3.5,3.5l8.4-8.4l8.4,8.4l3.5-3.5L15.5,12l8.4-8.4l-3.5-3.5L12,8.5L3.6,0.1L3.6,0.1z"></path>
				</svg>
			</button>
		</h2>
		<p id="areas">{ app.selectedTiles.join(", ") }</p>
		<div className="separator-line"></div>
		<p className="map-name-area">
			<label>{ trans("map_name") }</label>
			<input type="text" name="mapname" value={state.mapName} onChange={event => setState(prev => ({ ...prev, mapName: event.target.value }))} />
		</p>
		<p className="print-title">{ trans("print_title") }</p>
		<p>
			<label className="custom">
				<input type="checkbox" id="shortcuts" checked={ state.printShortcuts } onChange={shortcutsChange} />
				<span>{ trans("print_shortcuts") }</span>
			</label>
		</p>
		<p className="pois">
			<label className="custom">
				<input type="checkbox" id="pois" checked={state.addInfo} onChange={addInfoChange} />
				<span>{ trans("print_pois") }</span>
			</label>
		</p>
		<p>
			<label className="custom">
				<input type="checkbox" id="legend" checked={state.legend} onChange={event => setState(prev => ({ ...prev, legend: event.target.checked }))} />
				<span>{ trans("print_legend") }</span>
			</label>
		</p>
		<p className="list-id">
			<label className="custom">
				<input type="checkbox" id="listId" checked={state.listId} onChange={listChange} />
				<span>{ trans("print_list_id") }</span>
			</label>
		</p>
		<div className="reset options">
			<table>
				<tbody>
					<tr>
						<td>
							<label className="custom">
								<input type="radio" name="pos" value={LIST_ID.LEFT} checked={state.listValue === LIST_ID.LEFT} onChange={radioChange} / >
								<span>{ trans("common_left") }</span>
							</label>
						</td>
						<td className="first">
							<label className="custom">
								<input type="radio" name="pos" value={LIST_ID.MIDDLE} checked={state.listValue === LIST_ID.MIDDLE} onChange={radioChange} />
								<span>{ trans("common_middle") }</span>
							</label>
						</td>
						<td>
							<label className="custom">
								<input type="radio" name="pos" value={LIST_ID.RIGHT} checked={state.listValue === LIST_ID.RIGHT} onChange={radioChange} />
								<span>{ trans("common_right") }</span>
							</label>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<p className="reset">
			<button className="main-print-button" title={ trans("print_download") } type="button" onClick={download} disabled={state.wasSend}>
				{ trans("print_download") }
			</button>
			<button className="main-print-button" title={ trans("print_order_help") } type="button" onClick={goToHelp} disabled={state.wasSend}>
				{ trans("print_order_help") }
			</button>
			<button className="main-print-button" title={ trans("print_order_map") } type="button" onClick={goToOrder} disabled={state.wasSend}>
				{ trans("print_order_map") }
			</button>
			<span className={`download-info${state.showError ? " error" : ""}`}>
				{ state.downloadInfo }
			</span>
		</p>
	</div>;
}
