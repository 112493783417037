/* eslint-disable no-magic-numbers */
/* eslint-disable no-invalid-this */
/* eslint-disable func-names */
import { trans } from "~/utils/utils";
import { HAPTIC_TILE_ZOOMS } from "~/const";

export function getControlZoom(onClick: (zoom: number) => void) {
	const { SMap } = window;
	const ZOOM = {
		1: HAPTIC_TILE_ZOOMS[0],
		2: HAPTIC_TILE_ZOOMS[1],
		3: HAPTIC_TILE_ZOOMS[2],
	};
	const controlZoom = function () {
		SMap.Control.Visible.call(this);
		this._lastZoom = -1;

		this._build();
	};

	controlZoom.prototype = {
		...SMap.Control.prototype,
		...SMap.Control.Visible.prototype,
	};

	controlZoom.prototype.setOwner = function (owner) {
		SMap.Control.Visible.prototype.setOwner.call(this, owner);

		if (!owner) {
			return;
		}

		this._sc.push(this.getMap().getSignals().addListener(this, "map-redraw", "_redraw"));
		this._redraw();
	};

	controlZoom.prototype._build = function () {
		const cont = document.createElement("div");

		cont.classList.add("haptic-zooms");
		this._dom.btns = [];

		for (let ind = 1; ind <= 3; ind++) {
			const btn = document.createElement("button");

			btn.setAttribute("type", "button");
			btn.setAttribute("data-zoom", ZOOM[ind]);

			const title = document.createElement("span");

			title.classList.add("title");
			title.textContent = trans(`map_button_haptic_size${ind}`);
			btn.appendChild(title);

			const responsive = document.createElement("span");

			responsive.classList.add("responsive");
			responsive.textContent = trans(`map_button_haptic_size${ind}`)[0].toUpperCase();
			btn.appendChild(responsive);
			btn.addEventListener("click", () => {
				onClick && onClick(ZOOM[ind]);
			});
			cont.appendChild(btn);
			this._dom.btns.push(btn);

			if (ind !== 3) {
				const separator = document.createElement("span");

				separator.classList.add("separator");
				cont.appendChild(separator);
			}
		}

		this._dom.container = cont;
	};

	controlZoom.prototype._redraw = function () {
		const zoom: number = this.getMap().getZoom();

		if (this._lastZoom !== zoom) {
			this._dom.btns.forEach(btn => {
				btn.classList.remove("active");

				if (btn.getAttribute("data-zoom") === zoom.toString()) {
					btn.classList.add("active");
				}
			});
			this._lastZoom = zoom;
		}
	};

	return controlZoom;
}
