import { MouseEvent, ReactNode, Fragment } from "react";

import { trans, splitToParts, getRandomHexHash } from "~/utils/utils";
import { setZoom } from "~/stores/map";
import { appStore } from "~/stores/app";
import { USER_FORM_STATES, HAPTIC_TILE_ZOOMS, PROMOS, PROMOS_DATA } from "~/const";
import { getElsaLink, getTeiresiasLink, getHelpLink } from "~/utils/lang-data";

interface IPromoVariant {
	variant: "1" | "2" | "3" | "4" | "5" | "6";
}

export default function PromoVariant({
	variant = "1",
}: IPromoVariant) {
	const { app, setUserForm } = appStore(appState => ({
		app: appState.app,
		setUserForm: appState.setUserForm,
	}));
	const SOURCE_DATA = PROMOS[`PROMO${variant}`];

	function promoSetZoom(event: MouseEvent, zoom: number) {
		event.stopPropagation();
		event.preventDefault();
		setZoom(zoom);
	}

	function openForm(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		setUserForm(USER_FORM_STATES.HELP);
	}

	function getReplacerObj(): object {
		const output = {};

		Object.keys(SOURCE_DATA).forEach(value => {
			output[SOURCE_DATA[value]] = `{${SOURCE_DATA[value]}}`;
		});

		return output;
	}

	const REPLACE_ELEMS = [
		...variant === "1"
			? [{
				value: PROMOS.PROMO1.LITTLE,
				elem: <a href="#" onClick={event => promoSetZoom(event, HAPTIC_TILE_ZOOMS[0]) }>
					{ trans("map_button_haptic_size1").toLowerCase() }
				</a>,
			}, {
				value: PROMOS.PROMO1.MEDIUM,
				elem: <a href="#" onClick={event => promoSetZoom(event, HAPTIC_TILE_ZOOMS[1]) }>
					{ trans("map_button_haptic_size2").toLowerCase() }
				</a>,
			}, {
				value: PROMOS.PROMO1.BIG,
				elem: <a href="#" onClick={event => promoSetZoom(event, HAPTIC_TILE_ZOOMS[2]) }>
					{ trans("map_button_haptic_size3").toLowerCase() }
				</a>,
			}]
			: [],
		...variant === "2"
			? [{
				value: PROMOS.PROMO2.ELSA2,
				elem: <a href={getElsaLink(app.lang)} title={trans("elsa2")} target="_blank" rel="noreferrer">
					{ trans("elsa2") }
				</a>,
			}, {
				value: PROMOS.PROMO2.TEIRESIAS2,
				elem: <a href={getTeiresiasLink(app.lang)} title={trans("teiresias2")} target="_blank" rel="noreferrer">
					{ trans("teiresias2") }
				</a>,
			}]
			: [],
		...variant === "3"
			? [{
				value: PROMOS.PROMO3.FORM,
				elem: <a href="#" onClick={openForm} title={trans("promo_form")}>
					{ trans("promo_form") }
				</a>,
			}]
			: [],
		...variant === "4"
			? [{
				value: PROMOS.PROMO4.ELSA2_EMAIL,
				elem: <a href={`mailto:${PROMOS_DATA.ELSA2_EMAIL}`} title={PROMOS_DATA.ELSA2_EMAIL}>
					{ PROMOS_DATA.ELSA2_EMAIL }
				</a>,
			}]
			: [],
		...variant === "5"
			? [{
				value: PROMOS.PROMO5.TEIRESIAS2_EMAIL,
				elem: <a href={`mailto:${PROMOS_DATA.TEIRESIAS2_EMAIL}`} title={PROMOS_DATA.TEIRESIAS2_EMAIL}>
					{ PROMOS_DATA.TEIRESIAS2_EMAIL }
				</a>,
			}]
			: [],
		...variant === "6"
			? [{
				value: PROMOS.PROMO6.HELP,
				elem: <a href={getHelpLink(app.lang)} title={trans("promo_help")} target="_blank" rel="noreferrer">
					{ trans("promo_help") }
				</a>,
			}, {
				value: PROMOS.PROMO6.PROJECT,
				elem: <a href={PROMOS_DATA.BFMAPS_LINK} title={PROMOS_DATA.BFMAPS} target="_blank" rel="noreferrer">
					{ PROMOS_DATA.BFMAPS }
				</a>,
			}]
			: [],
	];
	const parts = splitToParts(trans(`promoNew_${variant}`, getReplacerObj()));

	function renderElem(value: string): ReactNode {
		const filtered = REPLACE_ELEMS.filter(item => item.value === value);

		if (filtered.length === 1) {
			return filtered[0].elem;
		}

		return null;
	}

	return <p>
		{ parts.map(partData => <Fragment key={getRandomHexHash()}>
			{ partData.isText && partData.value }
			{ !partData.isText && renderElem(partData.value) }
		</Fragment>) }
	</p>;
}
