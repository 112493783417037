import { useEffect, useState, useRef } from "react";

import { FLAGS_MAPPING } from "~/const";
import { classNames, trans } from "~/utils/utils";

// styly
import "./style.less";

interface IState {
	open: boolean;
}

interface ILangSwitcher {
	langs: Array<string>;
	onChange?: (langKey: string) => void;
}

export default function LangSwitcher({
	langs = [],
	onChange = () => {},
}: ILangSwitcher) {
	const [state, setState] = useState<IState>({
		open: false,
	});
	const divRef = useRef<HTMLDivElement>(null);
	const activeLang = langs[0];
	const restLangs = langs.slice(1);

	function openItem(langKey: string) {
		setState(prev => ({
			...prev,
			open: false,
		}));

		onChange(langKey);
	}

	function getClassName() {
		return classNames(["lang-switcher", state.open ? "open" : ""]);
	}

	function onClick(event: MouseEvent) {
		if (divRef.current && !divRef.current.contains(event.target as HTMLElement)) {
			setState(prev => ({
				...prev,
				open: false,
			}));
		}
	}

	function toggleOpen() {
		setState(prev => ({
			...prev,
			open: !prev.open,
		}));
	}

	useEffect(() => {
		if (state.open) {
			document.addEventListener("click", onClick);

			return () => {
				document.removeEventListener("click", onClick);
			};
		}

		return () => {};
	}, [state.open]);

	return <div className={getClassName()} ref={divRef}>
		<button type="button" onClick={toggleOpen} className="lang-switcher-item">
			<span className="icon">
				<img src={`/static/img/flags/${FLAGS_MAPPING[activeLang]}.png`} alt="" />
			</span>
			<span className="name">{ trans(`lang_${activeLang}`) }</span>
			<div className={`arrow-${state.open ? "up" : "down"}`}></div>
		</button>
		{ state.open && <ul>
			{ restLangs.map(langKey => <li key={langKey}>
				<button type="button" onClick={() => openItem(langKey)} title={ trans(`lang_${langKey}`) } className="lang-switcher-item">
					<span className="icon">
						<img src={`/static/img/flags/${FLAGS_MAPPING[langKey]}.png`} alt="" />
					</span>
					<span className="name">{ trans(`lang_${langKey}`) }</span>
				</button>
			</li>) }
		</ul> }
	</div>;
}
