import { LANG_DATA } from "~/const";

export function getLogoSrc(langKey: string): string {
	return LANG_DATA.LOGO_SRC[langKey];
}

export function getGDPRLink(langKey: string): string {
	return LANG_DATA.GDPR_LINK[langKey];
}

export function getElsaLink(langKey: string): string {
	return LANG_DATA.ELSA_LINK[langKey];
}

export function getTeiresiasLink(langKey: string): string {
	return LANG_DATA.TEIRESIAS_LINK[langKey];
}

export function getHelpLink(langKey: string): string {
	return LANG_DATA.HELP_LINK[langKey];
}

export function getYoutubeId(langKey: string): string {
	return LANG_DATA.YOUTUBE_ID[langKey];
}
