/* eslint-disable id-length */
import { create } from 'zustand';

import { DEF_POS } from "~/const";
import { IPosition } from "~/interfaces";

interface IPosStore {
	position: IPosition;
	setPosition: (x: number, y: number, z: number) => void;
}

function getPosition(): { x: number; y: number; z: number; } {
	const usp = new URLSearchParams(location.search);
	const paramX = usp.get("x");
	const paramY = usp.get("y");
	const paramZ = usp.get("z");
	const valueX = paramX ? parseFloat(paramX) : 0;
	const valueY = paramY ? parseFloat(paramY) : 0;
	const valueZ = paramZ ? parseFloat(paramZ) : 0;

	return {
		x: !isNaN(valueX) && valueX > 0 ? valueX : DEF_POS.x,
		y: !isNaN(valueY) && valueY > 0 ? valueY : DEF_POS.y,
		z: !isNaN(valueZ) && valueZ > 0 ? valueZ : DEF_POS.z,
	};
}

export const positionStore = create<IPosStore>(set => ({
	position: {
		...getPosition(),
	},
	setPosition: (x: number, y: number, z: number) => set(state => ({
		position: {
			...state.position,
			x,
			y,
			z,
		},
	})),
}));
