import { MouseEvent, Fragment } from "react";

import { CS_LANG, FOOTER_LINKS } from "~/const";
import { IFooterItem } from "~/interfaces";
import { trans } from "~/utils/utils";
import { appStore } from "~/stores/app";

// styly
import "./style.less";

export default function Footer() {
	const { app, showLegend } = appStore(appState => ({
		app: appState.app,
		showLegend: appState.showLegend,
	}));

	function openLink(event: MouseEvent, item: IFooterItem) {
		if (item.isLegend) {
			event.stopPropagation();
			event.preventDefault();
			showLegend(!app.showLegend);
		}
	}

	function getHref(hrefLangs: null|{[key: string]: string}) {
		if (hrefLangs) {
			return hrefLangs[app.lang] || hrefLangs[CS_LANG] || "";
		}

		return "#";
	}

	return <footer role="contentinfo" aria-labelledby="id-footer">
		<h6 id="id-footer" className="blind">{ trans("blind.footer") }</h6>
		{ FOOTER_LINKS.map((item, ind) => <Fragment key={item.title}>
			<a {...item.isLegend ? {} : { rel: "noreferrer", target: "_blank" }} href={ getHref(item.hrefLangs) } title={trans(item.title)} onClick={event => openLink(event, item)}>
				{ trans(item.title) }
			</a>
			{ ind !== FOOTER_LINKS.length - 1 && <span className="separator">•</span> }
		</Fragment>) }
	</footer>;
}
