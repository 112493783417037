export function getMarker() {
	const { SMap, JAK } = window;
	const markerOpt = {
		anchor: {
			left: 14,
			top: 46,
		},
		url: JAK.mel("a", { className: "animated-balloon" }, {
			width: "27px",
			height: "51px",
		}),
	};
	const marker = new SMap.WMMarker(SMap.Coords.fromWGS84(0, 0), null, markerOpt);

	return marker;
}
