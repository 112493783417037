import { PATHS } from "~/const";

export function getAPIFetch(sendData: object): Promise<any> {
	return fetch(PATHS.API, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(sendData),
	});
}
