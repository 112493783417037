/* eslint-disable no-magic-numbers */
/* eslint-disable no-invalid-this */
/* eslint-disable func-names */
const EXPIRES = {
	MAX: "Fri, 31 Dec 9999 23:59:59 GMT",
	MIN: "Thu, 01 Jan 1970 00:00:00 GMT",
};

/**
 * Add days to date.
 *
 * @param  {Date} date
 * @param  {Number} days
 * @return {Date}
 */
function _addDays(date: Date, days: number = 0): Date {
	const addTime = 1000 * 60 * 60 * 24 * days;

	return new Date(date.getTime() + addTime);
}

/**
 * Set cookie. Default expiration is 30 days from creation.
 *
 * @param  {String} name
 * @param  {String} value
 * @param  {Object} [optsArg]
 * @param  {Number|String|Date} [optsArg.expiration=null] Cookie expiration
 * @param  {String} [optsArg.path=""] Cookie path
 * @param  {String} [optsArg.domain=""] Cookie domain
 * @param  {String} [optsArg.secure=""] Cookie secure
 * @return {Boolean}
 */
export function setCookie(name: string, value: string, optsArg?: any): boolean {
	if (!name || /^(?:expires|max-age|path|domain|secure)$/iu.test(name)) {
		return false;
	}

	const opts = {
		expiration: _addDays(new Date(), 30),
		path: "",
		domain: "",
		secure: "",
		...optsArg,
	};
	let expires = "";

	if (opts.expiration) {
		switch (opts.expiration.constructor) {
			case Number:
				expires = `${opts.expiration === Infinity ? `; expires=${EXPIRES.MAX}` : `; max-age=`}${opts.expiration}`;
				break;

			case String:
				expires = `; expires=${opts.expiration}`;
				break;

			case Date:
				expires = `; expires=${opts.expiration.toUTCString()}`;
				break;

			default:
		}
	}

	document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${expires}${opts.domain ? `; domain=${opts.domain}` : ``}${opts.path ? `; path=${opts.path}`: ``}${opts.secure ? "; secure" : ``}`;

	return true;
}

/**
 * Get cookies by her name.
 *
 * @param  {String} name
 * @return {String}
 */
export function getCookie(name = ""): string {
	let cookieValue = null;

	if (document.cookie && document.cookie !== "") {
		const cookies = document.cookie.split(';');

		cookies.every(cookie => {
			const trimmedCookie = cookie.trim();

			if (trimmedCookie.substring(0, name.length + 1) === `${name}=`) {
				cookieValue = decodeURIComponent(trimmedCookie.substring(name.length + 1));

				return false;
			}

			return true;
		});
	}

	return cookieValue;
}

/**
 * Document contains cookie?
 *
 * @param  {String} name Cookie name
 * @return {Boolean}
 */
export function containsCookie(name: string): boolean {
	if (!name) {
		return false;
	}

	return new RegExp(`(?:^|;\\s*)${encodeURIComponent(name).replace(/[-.+*]/gu, `\\$&`)}\\s*\\=`, "u").test(document.cookie);
}

/**
 * Remove cookie.
 *
 * @param  {String} name Cookie name
 * @param  {String} [domain] Cookie domain
 * @param  {String} [path] Cookie path
 * @return {Boolean}
 */
export function removeCookie(name: string, domain: string, path: string): boolean {
	if (!containsCookie(name)) {
		return false;
	}

	document.cookie = `${encodeURIComponent(name)}=; expires=${EXPIRES.MIN}${domain ? `; domain=${domain}` : ``}${path ? `; path=${path}` : ``}`;

	return true;
}
