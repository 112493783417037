import { MouseEvent } from "react";

import { trans } from "~/utils/utils";
import { IMapset } from "~/interfaces";

import "./style.less";

interface IMapsets {
	mapsets: Array<IMapset>;
	onMapyCz?: () => void;
	onClose?: () => void;
}

export default function Mapsets({
	mapsets = [],
	onMapyCz = () => {},
	onClose = () => {},
}: IMapsets) {
	function openItem(event: MouseEvent, item: IMapset) {
		event.stopPropagation();
		event.preventDefault();

		if (item.isMapyCz) {
			onMapyCz();
		} else {
			onClose();
		}
	}

	return <div id="mapset-switch" onClick={() => onClose()}>
		<ul>
			{ mapsets.map(item => <li className={`${item.id}${item.active ? " active" : ""}`} key={item.id} onClick={event => openItem(event, item)}>
				<img alt={ trans(item.titleKey) } src={item.imgSrc} />
				<span>{ trans(item.titleKey) }</span>
			</li>) }
		</ul>
	</div>;
}
