export function getSuggestItem() {
	const { SMap } = window;

	class SuggestItem extends SMap.SuggestItem {
		_createIcon() {
			const icon = document.createElement("span");

			icon.classList.add("image");

			const iconSrc = this._getIcon();

			if (iconSrc) {
				icon.setAttribute("style", `background: url(${iconSrc}) center center no-repeat`);
			} else {
				icon.classList.add("default");
			}

			return icon;
		}
	}

	return SuggestItem;
}
