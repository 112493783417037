import { positionStore } from "~/stores/position";
import { getLegendForZoom, trans } from "~/utils/utils";

// styly
import "./style.less";

export default function Legend() {
	const { position } = positionStore(positonState => ({
		position: positonState.position,
	}));
	const items = getLegendForZoom(position.z);

	return <div className="legend-container">
		<div className="legend-items">
			{ items.map(itemData => <div className="legend-item" key={itemData.item}>
				<span className="icon-cover legend">
					<span className={itemData.icon} />
				</span>
				<span className="trans">
					{ trans(itemData.item) }
				</span>
			</div>) }
		</div>
	</div>;
}
