import { useState, useEffect } from "react";

import Map from "~/components/Map";
import Table from "~/components/Table";
import Mapsets from "~/components/Mapsets";
import Header from "~/components/Header";
import Content from "~/components/Content";
import Footer from "~/components/Footer";
import Legend from "~/components/Legend";
import SearchResults from "~/components/SearchResults";
import Promo from "~/components/Promo";
import Print from "~/components/Print";
import UserForm from "~/components/UserForm";
import ThankYou from "~/components/ThankYou";
import { classNames, trans, getVisibleContent, getMapyczLink, debounce } from "~/utils/utils";
import { MAPSETS, USER_FORM_STATES, VISIBLE_CONTENT, SMALL_MEDIA_QUERY } from "~/const";
import { appStore } from "~/stores/app";
import { clearTiles, syncPort } from "~/stores/map";
import { positionStore } from "~/stores/position";

// styly
import "./style.less";

interface IState {
	mapIsVisible: boolean;
	showMapsets: boolean;
	withHelp: boolean;
}

export default function HomePage() {
	const { app, setUserForm, showThankYou, setResponsive, setLayout } = appStore(appState => ({
		app: appState.app,
		setUserForm: appState.setUserForm,
		showThankYou: appState.showThankYou,
		setResponsive: appState.setResponsive,
		setLayout: appState.setLayout,
	}));
	const [state, setState] = useState<IState>({
		mapIsVisible: true,
		showMapsets: false,
		withHelp: false,
	});
	const GRAY_CLASS = "gray-mode";
	const visibleContent = getVisibleContent(app);
	/* eslint-disable no-magic-numbers */
	const resizeCb = debounce(() => {
		syncPort();
	}, 500);

	function getMapClass(): string {
		return classNames(["map-button", "map", state.mapIsVisible ? "active" : ""]);
	}

	function getTableClass(): string {
		return classNames(["map-button", "table", state.mapIsVisible ? "" : "active"]);
	}

	function goToMapyCz() {
		setState(prev => ({
			...prev,
			showMapsets: false,
		}));

		const position = positionStore.getState().position;

		location.href = getMapyczLink(position);
	}

	function closeUserForm() {
		setUserForm(USER_FORM_STATES.NONE);
	}

	function onThankYou(withHelp: boolean) {
		clearTiles();
		setState(prev => ({
			...prev,
			withHelp,
		}));
		showThankYou(true);
	}

	function thankYouClose() {
		showThankYou(false);
	}

	function getLayoutClass() {
		return classNames([
			"layout",
			app.isResponsive ? "responsive-mode" : "",
			app.isResponsive ? app.isLayoutMode ? "layout-mode" : "map-mode" : "",
		]);
	}

	function blockMapClick() {
		if (app.isLayoutMode) {
			setLayout(false);
		} else {
			setLayout(true);
		}

		setTimeout(() => {
			syncPort();
		}, 0);
	}

	useEffect(() => {
		const media = window.matchMedia(SMALL_MEDIA_QUERY);

		setResponsive(media.matches);

		const listener = () => {
			setResponsive(media.matches);
		};

		media.addEventListener("change", listener);
		window.addEventListener('resize', resizeCb);

		return () => {
			media.removeEventListener("change", listener);
			window.addEventListener('resize', resizeCb);
		};
	}, []);

	useEffect(() => {
		if (visibleContent === VISIBLE_CONTENT.PROMO) {
			document.body.classList.add(GRAY_CLASS);
		} else {
			document.body.classList.remove(GRAY_CLASS);
		}
	}, [visibleContent]);

	return <div className={getLayoutClass()}>
		{ app.isResponsive && <div id="block-map" onClick={blockMapClick}>
			{ !app.isLayoutMode && <p>{ trans("show_panel") }</p> }
		</div> }
		<div className="map-area">
			<Map isVisible={state.mapIsVisible} />
			{ !state.mapIsVisible && <Table /> }
			<div className="map-buttons">
				<button type="button" className="mapset" title={ trans("map_button_mapset") } onClick={() => setState(prev => ({ ...prev, showMapsets: !prev.showMapsets }))}>
					<span>{ trans("map_button_mapset") }</span>
				</button>
				<button type="button" className={getMapClass()} title={ trans("map_button_map") } onClick={() => setState(prev => ({ ...prev, mapIsVisible: true }))}>
					{ trans("map_button_map") }
				</button>
				<button type="button" className={getTableClass()} title={ trans("map_button_table") } onClick={() => setState(prev => ({ ...prev, mapIsVisible: false }))}>
					{ trans("map_button_table") }
				</button>
			</div>
			{ visibleContent === VISIBLE_CONTENT.USER_FORM && <div className="lock-popup-cover">
				<div className="lock-popup">
					<button type="button" role="link" className="main-print-button" onClick={closeUserForm}>
						{ trans("print_contact_additional_back") }
					</button>
				</div>
			</div> }
		</div>
		<div className="panel-area">
			<div className="shadow-area">
				<Header />
				<Content>
					{ visibleContent === VISIBLE_CONTENT.LEGEND && <Legend /> }
					{ visibleContent === VISIBLE_CONTENT.USER_FORM && <UserForm onBack={closeUserForm} onThankYou={onThankYou} /> }
					{ visibleContent === VISIBLE_CONTENT.SEARCH_RESULTS && <SearchResults /> }
					{ visibleContent === VISIBLE_CONTENT.PRINT && <Print /> }
					<Promo visible={visibleContent === VISIBLE_CONTENT.PROMO} />
					{ visibleContent === VISIBLE_CONTENT.THANK_YOU && <ThankYou withHelp={state.withHelp} onClose={thankYouClose} />}
				</Content>
				<Footer />
			</div>
		</div>
		{ state.showMapsets && <Mapsets mapsets={MAPSETS} onMapyCz={goToMapyCz} onClose={() => setState(prev => ({ ...prev, showMapsets: false }))} /> }
	</div>;
}
